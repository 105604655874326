:root {
    --swiper-navigation-size: 24px;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    background-position: center;
    object-fit: cover;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #fff;
}

.swiper-pagination-bullet {
    background: rgba(217, 217, 217, 1);
    width: 8.5px;
    height: 8.5px;
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    width: fit-content;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 2.5px;
}

.swiper-button-next,
.swiper-button-prev {
    margin-top: 18px;
    color: #fff;
    width: 90px;
    opacity: 44%;
    /* font-weight: 900; */
    -webkit-text-stroke: 3px;
}

.ipad-frame {
    z-index: 9999;
}

.ipad-slider {
    z-index: 9999;
}

@media only screen and (max-width: 900px) {

    .swiper-button-next,
    .swiper-button-prev {
        padding-top: 100px;
        color: #fff;
        width: 60px;
    }

    .slideContainer {
        background: linear-gradient(to top, rgba(186, 31, 26, 1), rgba(0, 0, 0, 0.5)) !important;
    }

    .slideContainer::before {
        display: none !important;
    }
}