.photo-container {
    position: relative;
    margin: 0 auto;
}

.photo-container img:hover {
    /* scale: 1.05; */
    transform: translate(8px, -8px);
    /* transform: translateX(12px); */
    transition: all ease-in-out .3s;
}

.photo-containerr img:hover {
    /* scale: 1.05; */
    transform: translate(-8px, -8px);
    /* transform: translateX(12px); */
    transition: all ease-in-out .3s;
}

.photo-containers img:hover {
    /* scale: 1.05; */
    transform: translate(-8px, -8px);
    /* transform: translateX(12px); */
    transition: all ease-in-out .3s;
}

.photo-containerr {
    position: relative;
}

.photo-containers {
    position: relative;
}

.photo-containers img {
    width: 150px;
    height: 150px;
    object-fit: contain;
    background-color: #cacaca;
}

.photo-container img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    background-color: #cacaca;
}

.photo-containerr img {
    width: 300px;
    height: 300px;
    object-fit: cover;
    background-color: #cacaca;
}

.photo-container::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 20px;
    left: -20px;
    /* border: solid 3px black; */
    border-radius: 4px;
    background: #ECEDEF;
    z-index: -1;
}

.photo-containerr::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 20px;
    left: 20px;
    /* border: solid 3px black; */
    border-radius: 4px;
    background: #ECEDEF;
    z-index: -1;
}

.photo-containers::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 10px;
    left: 10px;
    /* border: solid 3px black; */
    border-radius: 4px;
    background: #ECEDEF;
    z-index: -1;
}