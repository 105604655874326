.container::after {
    content: 'HMJ-TI';
    position: absolute;
    bottom: 0;
    display: inline-block;
    right: 0;
    font-weight: 700;
    font-size: 196px;
    line-height: 80%;
    z-index: 1;
    letter-spacing: 1.5px;
    margin-right: -15px;
    color: rgba(255, 255, 255, 0.15);
}

.link {
    color: #fff;
    position: relative;
    font-size: 13px;
    text-decoration: none;
}

.link::after {
    content: '';
    position: absolute;
    height: 1.5px;
    bottom: -4px;
    left: 0;
    border-radius: 3px;
    background: #fff;
    width: 120px;
}

.link-mobile {
    color: #fff;
    position: relative;
    font-size: 13px;
    text-decoration: none;
    padding-bottom: 6px;
    display: none;
}

.link-mobile::after {
    content: '';
    position: absolute;
    height: 1.5px;
    bottom: 0;
    left: 0;
    border-radius: 3px;
    background: #fff;
    width: 120px;
}

.product {
    transition: all .5s;
    object-fit: contain;
    overflow: hidden;
}

.product img:hover {
    transform: scale(1.25);
}

@media (max-width: 900px) {
    .container::after {
        font-size: 96px;
        margin-right: -6px;
    }

    .link {
        display: none;
        padding-bottom: 0;
    }

    .link-mobile {
        display: block;
    }
}